<template>
  <div>
    <div v-if="selection.id" class="row">
      <div
        v-for="recipe in selection.recipes"
        :key="recipe.id"
        class="col-lg-6 px-0"
      >
        <div class="row border-bottom m-1">
          <div class="col-3">
            <b-img
              :src="recipe.thumb_list_url"
              fluid
              thumbnail
              style="max-width: 72px"
            />
          </div>
          <div class="col-9 px-0">
            <h5>{{ recipe.label }}</h5>
            <button
              class="btn btn-sm btn-primary mr-1"
              @click.prevent="updateRelation('remove', recipe.id)"
            >
              <font-awesome-icon icon="minus" />
            </button>
            <b class="mr-1">{{ getRecipeQuantity(recipe.id) }}</b>
            <button
              class="btn btn-sm btn-primary"
              @click.prevent="updateRelation('add', recipe.id)"
            >
              <font-awesome-icon icon="plus" />
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- <pre>{{ selection }}</pre> -->
  </div>
</template>

<script>
import { BImg } from 'bootstrap-vue'

export default {
  components: {
    BImg,
  },
  props: {
    userSelection: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      loading: false,
      selection: {},
      selections: [],
      selectionIndex: 0,
    }
  },
  computed: {
    recipesIds() {
      const ids = []
      if (this.selection && this.selection.recipes) {
        this.selection.recipes.forEach((r) => {
          ids.push(r.id)
        })
      }
      return ids
    },
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      const respSelection = await this.$http.get(
        '/recipes/selections/by-date/',
        {
          params: {
            start_at: this.userSelection.recipes_selection_start_at,
          },
          headers: {
            'X-Fields':
              'id,status,start_at,end_at,week_of_year,recipes_relations_count,recipes{id,label,thumb_list_url}',
          },
        }
      )
      this.selection = respSelection.data
    },
    async updateRelation(action, recipeId) {
      const respCsrf = await this.$http.get('/auth/csrf/')
      const resp = await this.$http.post(
        `/users/selections/${this.userSelection.id}/relations/`,
        { action, recipe_id: recipeId },
        {
          headers: {
            'X-Fields':
              'recipes_relations_quantities,' +
              'selections_relations{id,quantity,recipe_id,recipe{label,thumb_list_url}}',
            'X-CSRF-TOKEN': respCsrf.data.csrf,
          },
        }
      )
      this.$emit('updated', resp.data)
    },
    getRecipeQuantity(recipeId) {
      let qty = 0
      this.userSelection.selections_relations.forEach((relation) => {
        if (relation.recipe_id === recipeId) {
          qty = relation.quantity
        }
      })
      return qty
    },
  },
}
</script>
