<template>
  <dl class="row">
    <dt class="col-sm-4 text-right text-muted">#</dt>
    <dd class="col-sm-8">
      <b><copy-value :value="selection.id" /></b>
    </dd>
    <dt class="col-sm-4 text-right text-muted">Formule</dt>
    <dd class="col-sm-8">Formules {{ selection.limit }} plats</dd>
    <template v-if="selection.user">
      <dt class="col-sm-4 text-right text-muted">Client</dt>
      <dd class="col-sm-8">
        <router-link
          :to="{ name: 'client', params: { id: selection.user.id } }"
        >
          {{ selection.user.fullname }}
        </router-link>
      </dd>
    </template>
    <dt class="col-sm-4 text-right text-muted">Abonnement #</dt>
    <dd class="col-sm-8">
      <router-link :to="`/subscriptions/${selection.user_subscription_id}`">
        {{ selection.user_subscription_id }}
      </router-link>
    </dd>
    <dt class="col-sm-4 text-right text-muted">Paiement #</dt>
    <dd class="col-sm-8">
      {{ selection.payment_subscription_id || '-' }}
      ({{ selection.subscription.payment_service }})
    </dd>
    <dt class="col-sm-4 text-right text-muted">Statut du paiement</dt>
    <dd class="col-sm-8">
      {{ selection.payment_status || '-' }}
    </dd>
    <dt class="col-sm-4 text-right text-muted">Transaction #</dt>
    <dd class="col-sm-8">
      {{ selection.payment_id || '-' }}
    </dd>
    <dt class="col-sm-4 text-right text-muted">Créé le</dt>
    <dd class="col-sm-8">
      <span v-if="selection.created_at">
        {{ $dayjs(selection.created_at).format('DD/MM/YYYY à HH:mm') }}
      </span>
    </dd>
    <dt class="col-sm-4 text-right text-muted">Livraison le</dt>
    <dd class="col-sm-8">
      <span v-if="selection.delivery_at">
        {{ $dayjs(selection.delivery_at).format('DD/MM/YYYY') }}
      </span>
    </dd>
    <dt class="col-sm-4 text-right text-muted">Sélection</dt>
    <dd class="col-sm-8">
      {{ $t(`SELECTION.${selection.choice}`) }}
    </dd>
  </dl>
</template>

<script>
export default {
  name: 'UserSelectionCard',
  props: {
    selection: {
      type: Object,
      default() {
        return {}
      },
    },
    subscription: {
      type: Object,
      default() {
        return {}
      },
    },
  },
}
</script>
