<template>
  <div v-if="selection.id">
    <b-card>
      <div class="row">
        <div class="col-lg-8">
          <h3>
            Livrée le
            {{ $dayjs(selection.delivery_at).format('DD/MM/YYYY') }}
            <br />
            <small>
              Créé le {{ $dayjs(selection.created_at).format('DD-MM-YYYY') }}
              Mise à jour le
              {{ $dayjs(selection.updated_at).format('DD-MM-YYYY à HH:mm') }}
            </small>
          </h3>
        </div>
        <div class="col-lg-4 text-right">
          <a
            v-if="selection.unpaid_url"
            :href="selection.unpaid_url"
            target="_blank"
            class="btn btn-warning mr-1"
          >
            <font-awesome-icon icon="link" />
            Régularisation
          </a>
          <a
            :href="selection.evaluation_url"
            target="_blank"
            class="btn btn-info mr-1"
          >
            <font-awesome-icon icon="star" />
            ÉVALUATION
          </a>
          <a :href="selection.invoice_url" target="_blank" class="btn btn-info">
            <font-awesome-icon icon="file-pdf" />
            Facture
          </a>
        </div>
      </div>
      <b-tabs content-class="mt-2">
        <b-tab title="Propriétés" active>
          <user-selection-card
            :selection="selection"
            :subscription="selection.subscription"
          />
        </b-tab>
        <b-tab title="Métadonnées">
          <pre>{{ selection }}</pre>
        </b-tab>
      </b-tabs>
    </b-card>
    <b-card>
      <div class="row">
        <div class="col-md-6">
          <h3>Plats</h3>
        </div>
        <div class="col-md-6 text-right"></div>
      </div>
      <div class="row">
        <div class="col-lg-11">
          <user-selection-recipes
            v-if="selectionEdit == true"
            :user-selection="selection"
            @updated="init()"
          />
          <div v-else class="row">
            <div
              v-for="selection_relation in selection.selections_relations"
              :key="selection_relation.id"
              class="col-lg-6 px-0"
            >
              <div class="row border-bottom m-1">
                <div class="col-3">
                  <b-img
                    :src="selection_relation.recipe.thumb_list_url"
                    fluid
                    thumbnail
                    style="max-width: 72px"
                  />
                </div>
                <div class="col-9 px-0">
                  <h5>
                    {{ selection_relation.recipe.label }}
                    <br />
                    <small>
                      Quantité {{ selection_relation.quantity }}
                      - le
                      {{
                        $dayjs(selection_relation.created_at).format(
                          'DD/MM/YYYY HH:mm'
                        )
                      }}
                    </small>
                    <small v-if="selection_relation.evaluation > 0">
                      <br />
                      <font-awesome-icon
                        v-for="evaluation in [
                          ...Array(selection_relation.evaluation),
                        ]"
                        :key="`evaluation${evaluation}`"
                        icon="star"
                        class="text-success"
                      />
                    </small>
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <p
            v-if="
              selection.selections_relations.length == 0 &&
              selectionEdit == false
            "
            class="text-center text-muted"
          >
            <em>Pas de sélection pour l’instant</em>
          </p>
        </div>
        <div class="col-lg-1 text-right px-0">
          <button
            v-if="
              selection.subscription.status == 'ENABLED' ||
              selection.subscription.status == 'PAUSE' ||
              selection.subscription.status == 'CANCEL_AT_PERIOD_END'
            "
            class="btn btn-sm btn-info mb-1"
            @click.prevent="selectionEdit = !selectionEdit"
          >
            <font-awesome-icon icon="pencil-alt" class="fa-fw" />
          </button>
          <br />
          <button
            v-if="
              (selection.subscription.status == 'ENABLED' ||
                selection.subscription.status == 'PAUSE' ||
                selection.subscription.status == 'CANCEL_AT_PERIOD_END') &&
              selection.recipes_relations_quantities == 0
            "
            v-b-modal.modal-fill
            class="btn btn-sm btn-warning"
            @click.prevent="fillSelectionId = selection.id"
          >
            <font-awesome-icon icon="fill-drip" class="fa-fw" />
          </button>
        </div>
      </div>
    </b-card>
    <b-modal
      id="modal-fill"
      ref="fill-selection"
      centered
      hide-header-close
      title="Sélection automatique"
    >
      <p class="my-2">
        Confirmez-vous la création d'une sélection automatique ?
      </p>
      <template #modal-footer="{ ok, cancel, hide }">
        <b-button
          size="sm"
          variant="danger"
          @click="fillSelection(fillSelectionId)"
        >
          Confirmer
        </b-button>
        <b-button size="sm" variant="outline-secondary" @click="hide('forget')">
          Annuler
        </b-button>
      </template>
    </b-modal>
    <debug>{{ selection }}</debug>
  </div>
</template>

<script>
import UserSelectionCard from '@/components/UserSelectionCard.vue'
import UserSelectionRecipes from '@/components/UserSelectionRecipes.vue'
import { BCard, BTabs, BTab, BImg, BModal, BButton } from 'bootstrap-vue'

const selectionFields =
  'id,status.created_at,start_at,end_at,choice,week_of_year,' +
  'recipes_selection_start_at,recipes_relations_count,' +
  'recipes_relations_quantities,limit,user_subscription_id,' +
  'invoice_url,unpaid_url,evaluation_url,payment_status,payment_id,payment_subscription_id,delivery_at,' +
  'selections_relations{id,created_at,quantity,evaluation,recipe_id,production_task_id,recipe{label,thumb_list_url}},' +
  'user{id,fullname},' +
  'subscription'

export default {
  components: {
    UserSelectionCard,
    UserSelectionRecipes,
    BTabs,
    BTab,
    BCard,
    BImg,
    BModal,
    BButton,
  },
  data() {
    return {
      selectionEdit: false,
      selection: {},
      constants: {},
      fillSelectionId: null,
    }
  },
  async created() {
    this.init()
  },
  methods: {
    async init() {
      const respConstants = await this.$http.get('/constants/', {
        params: { keys: 'CURRENT_WEEK,NEXT_WEEK' },
      })
      this.constants = respConstants.data
      const resp = await this.$http.get(
        `/users/selections/${this.$route.params.id}/`,
        {
          headers: {
            'X-Fields': selectionFields,
          },
        }
      )
      this.selection = resp.data
    },
    async addSelection() {
      const respCsrf = await this.$http.get('/auth/csrf/')
      await this.$http.post(
        '/users/selections/',
        {
          user_id: this.subscription.user_id,
          user_subscription_id: this.$route.params.id,
          start_at: this.constants.NEXT_WEEK.start_at,
          end_at: this.constants.NEXT_WEEK.end_at,
          limit: this.subscription.plan.quantity,
        },
        {
          headers: {
            'X-Fields': 'id',
            'X-CSRF-TOKEN': respCsrf.data.csrf,
          },
        }
      )
    },
    async fillSelection(selectionId) {
      if (this.fillSelectionId) {
        const respCsrf = await this.$http.get('/auth/csrf/')
        await this.$http.post(
          `/users/selections/${selectionId}/fill/`,
          {},
          {
            headers: {
              'X-Fields':
                'recipes_relations_quantities,' +
                'selections_relations{id,quantity,recipe_id,recipe{label,thumb_list_url}}',
              'X-CSRF-TOKEN': respCsrf.data.csrf,
            },
          }
        )
        this.$refs['fill-selection'].hide()
        this.init()
      }
    },
  },
}
</script>
